<template>
  <div>
    <b-card
      no-body
      class="mr-1"
      style="font-size: 20px"
    >
      <b-card-header class="p-1 saj-title">
        <p style="font-size: 22px">
          {{ $t("Request List") }}
        </p>
      </b-card-header>
      <b-card-body>
        <b-row class="justify-content-start ml-2">
          <!-- {{ selectedSub }} -->
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2 pb-2"
          >
            <!-- <label style="font-size: 20px">Id:</label> -->
            <label
              class="saj-title"
              style=""
            >{{ $t("Subsidiary") }}:</label>
            <b-form-select
              v-model="selectedSub"
              :options="subsidiaryList"
              :value="subsidiaryList"
              :placeholder="$t('Subsidiary')"
              :disabled="roles.selectedRole !== 1"

              class="saj-text"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2 pb-2"
          >
            <!-- <label style="font-size: 20px">Id:</label> -->
            <label
              class="saj-title"
              style=""
            >{{ $t("Status") }}:</label>
            <b-form-select
              v-model="selectedStatus"
              :options="statusList"
              :value="statusList"
              :placeholder="$t('Status')"
              :disabled="selectedSub === null"

              class="saj-text"
            />
          </b-col>
          <!-- <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2 pb-2"
          >

            <b-form-select
              v-model="selectedYear"
              :options="yearList"
              :value="yearList"
              :placeholder="$t('Year')"
              size="lg"
              class="saj-text"
            />
          </b-col> -->
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2 mr-1"
          >
            <label
              class="saj-title"
              style=""
            >{{ $t("Search") }}:</label>
            <b-form-input
              v-model="searchname"
              :placeholder="$t('Search')"
              :disabled="selectedSub === null"
            />
          </b-col>
          <b-row
            class="flex-row flex-column mr-1"
            style="margin-top:12px"
          >
            <b-col
              cols="12"
              class="d-flex align-items-center"
            >
              <b-button
                md="4"
                variant="primary"
                class="d-flex justify-content-center saj-button"

                block
                @click="getDetails()"
              >

                <span class="d-md-none d-lg-block"> {{ $t("Search") }} </span>
              </b-button>
            </b-col>
          </b-row>
          <b-row style="margin-top:12px">
            <b-col class="d-flex align-items-center">
              <b-button
                variant="primary"
                class="mr-1 saj-button"

                @click="clearButton()"
              >
                {{ $t("Clear") }}
              </b-button>
            </b-col>
          </b-row>
        </b-row>
      </b-card-body>
      <span class="ml-5">{{ $t("Total pending status") }}:
        <b-badge
          style="border-radius:20vh"
          class="saj-title"
          variant="warning"
        >
          {{ total }}
        </b-badge>
      </span>
      <div class="container">
        <b-table
          responsive
          :items="educationList"
          :fields="fields"
          :filter="searchInput"
          :per-page="perPage"
          :current-page="currentPage"
          bordered
          show-empty
          class="saj-title mt-1 mb-1 "
          @filtered="onFiltered"
        >
          <!-- A custom formatted column -->
          <template #head()="data">
            <span
              class="saj-text"
              style=""
            >{{ $t(data.label) }}</span>
          </template>
          <!-- <template #cell(index)="data">
            <div class="saj-text d-flex justify-content-center">
              {{ currentPage === 1 ? data.index + 1 : (data.index + 1)+((currentPage) *10) }}
               {{(data.index) + 1 }}
            </div>
          </template> -->
          <template #empty="">
            <h4 style="text-align: center; font-style: italic">
              {{ $t('There are no records to show') }}
            </h4>
          </template>
          <template #emptyfiltered="">
            <h4 style="text-align: center; font-style: italic">
              {{ $t('There are no records to show') }}
            </h4>
          </template>
          <template #cell(index)="data">
            <div
              class="saj-text d-flex justify-content-center"
            >
              {{ currentPage === 1 ? data.index + 1 : (data.index + 1)+((currentPage-1) *20) }}
            </div>
          </template>
          <template #cell(nama)="data">
            <div class="saj-text d-flex justify-content-left">
              {{ data.item.employee_name }}
            </div>
          </template>
          <template #cell(date)="data">
            <div class="saj-text d-flex justify-content-center">
              {{ DateChanged(data.item.updated_date) }}
            </div>
          </template>
          <template #cell(qualification)="data">
            <div class="saj-text d-flex justify-content-center">
              {{ $t(data.item.qualification) }}
            </div>
          </template>
          <template #cell(status)="data">
            <div class="saj-text d-flex justify-content-center">
              <b-badge
                style="border-radius:20vh"
                :variant="`${data.item.status }`=== 'approved' ? `success` : (`${data.item.status }` === 'pending' ? `warning` : `danger`)"
              >
                {{ $t(data.item.status) }}
              </b-badge>
            </div>
          </template>
          <template #cell(action)="data">
            <div
              class="saj-text d-flex justify-content-center"
              style="
            font-family: Poppins;
            font-weight: 400;
            font-style: normal;
            color: #464e5f;
          "
            >
              <!-- {{ data.item.education_id }} -->
              <feather-icon
                v-b-modal.requester-info
                v-b-tooltip.hover.bottom="$t('See Details')"
                icon="MoreVerticalIcon"
                size="25"
                class="saj-text"
                style="color: purple"
                @click="eduData=data.item"
              />
              <!-- <feather-icon
                icon="CheckSquareIcon"
                size="25"
                style="color: green"
                class="saj-text"
              /> -->
            </div>
          </template>
        </b-table>
        <!-- Pagination -->
        <b-row>
          <b-col>
            <span
              class="saj-text"
              align="start"
            >
              <!-- {{ $t("Showing") }} 1 {{ $t("to") }} 2 {{ $t("of") }} 3 {{ $t("entries") }} -->
              <!-- {{ $t("Showing") }} {{ currentPage }} {{ $t("to") }} 2 {{ $t("of") }} {{ totalRows }} {{ $t("entries") }} -->
              {{ $t("Showing") }} {{ totalRows > perPage*currentPage ? perPage*currentPage : totalRows }} {{ $t("from") }} {{ totalRows }} {{ $t("entries") }}

            </span>
          </b-col>
          <b-col>
            <b-pagination
              v-model="currentPage"
              :value="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              pills
              class="saj-text mb-1 justify-content-end"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                  class="saj-text"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                  class="saj-text"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      id="requester-info"
      :hide-header="true"
      :hide-footer="true"
      :centered="true"
      size="lg"
    >
      <!-- $bvModal.hide(nama modal id) untuk tutup modal-->
      <reqInfo
        :education-data="eduData"
        @refresh-table="getDetails()"
        @close="$bvModal.hide('requester-info')"
      />
    </b-modal>
  </div>
</template>

<script>

import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BTable,
  BButton,
  BFormInput,
  BFormSelect,
  VBPopover,
  BPopover,
  BPagination,
  BBadge,
  VBTooltip,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import reqInfo from "@/component/requester-info.vue"
import moment from "moment"
import { mapGetters } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormInput,
    BFormSelect,
    BTable,
    // eslint-disable-next-line vue/no-unused-components
    BPopover,
    BPagination,
    BBadge,
    reqInfo,

  },
  directives: {
    "b-popover": VBPopover,
    "b-tooltip": VBTooltip,
    Ripple,
  },

  data() {
    return {
      totalPending: null,
      total: null,
      subsidiary_id: null,
      userId: null,
      searchInput: '',
      searchname: '',
      selectedStatus: '',
      eduData: null,
      selectedSub: null,
      // selectedYear: '',
      // allStatus: [],
      // statusList: [],
      // yearList: [],
      currentPage: 1,
      perPage: 20,
      totalRows: null,
      subsidiaryList: [{ text: `${this.$i18n.t('Choose Subsidiary')}`, value: null, disabled: true }],
      educationList: [],
      statusList: [
        { value: "", text: `${this.$t(`Choose Status`)}`, disabled: true },
        { value: 'approved', text: `${this.$t(`Approved`)}` },
        { value: 'pending', text: `${this.$t(`Pending`)}` },
        { value: 'rejected', text: `${this.$t(`Rejected`)}` },

      ],
      items: [],
      fields: [
        {
          key: 'index', label: 'No.', thClass: 'text-center',
        },
        {
          key: 'nama', label: 'Name', thClass: 'text-center',
        },
        {
          key: 'date', label: 'Date', thClass: 'text-center',
        },
        {
          key: 'qualification', label: 'Qualification', thClass: 'text-center',
        },
        {
          key: 'status', label: 'Status', thClass: 'text-center',
        },
        {
          key: 'action', label: 'Action', thClass: 'text-center',
        },
      ],
    }
  },

  computed: {
    ...mapGetters(['roles']),
  },
  watch: {
    // eslint-disable-next-line func-names
    '$i18n.locale': function () {
      this.statusList = [
        { value: "", text: `${this.$t(`Choose Status`)}`, disabled: true },
        { value: 'approved', text: `${this.$t(`Approved`)}` },
        { value: 'pending', text: `${this.$t(`Pending`)}` },
        { value: 'rejected', text: `${this.$t(`Rejected`)}` },

      ]
    },
  },

  mounted() {
    this.getSub_Id()
    this.getSubsidiaryList()
  },
  methods: {
    getSub_Id() {
      this.$axios.get(`${this.$baseUrl}/users/current_user`).then(response => {
        this.subsidiary_id = response.data.data.employee.business_unit
        this.selectedSub = response.data.data.employee.business_unit
        // console.log("ini subs", this.subsidiary_id)
        this.getDetails()
        this.getTotal()
      })
    },
    // checkUser() {
    //   const currentRole = this.roles.selectedRole
    //   if (currentRole === 6){
    //     this.getDetailsHR()
    //   } else if (currentRole === 1){
    //     this.getDetails()
    //   }
    // },
    onFiltered(filteredItems){
      this.totalRows = filteredItems.length
    },
    clearButton() {
      if (this.roles.selectedRole === 1){
        this.selectedSub = null
      }
      this.selectedStatus = ""
      this.searchname = ""
      this.getDetails()
    },
    DateChanged(date) {
      const newDay = moment(date, "").format("D-MM-yyyy")
      return newDay
    },
    // getDetails() {
    //   const data = new FormData()
    //   data.append('status', this.selectedStatus)
    //   this.searchInput = this.searchname
    //   this.$axios.post(`${this.$baseUrl}/admin/getEducationByAdmin`, data).then(response => {
    //     if (response.data.data.length !== 0){
    //       this.educationList = response.data.data
    //       this.totalRows = this.educationList.length
    //     }

    //   })
    // },
    getDetails() {
      const data = new FormData()
      // if (this.roles.selectedRole === 1) {
      this.searchInput = this.searchname
      if (this.roles.selectedRole === 1){
        data.append('subsidiary_id', this.selectedSub)
      }
      data.append('status', this.selectedStatus)
      this.$axios.post(`${this.$baseUrl}/admin/getEducationByAdmin?subsidiary_id=${this.subsidiary_id}`, data).then(response => {
        if (response.data.data.length !== 0){
          this.educationList = response.data.data
          this.totalRows = this.educationList.length
          const pendingTot = this.educationList.filter(x => (x.status === 'pending'))
          this.total = pendingTot.length

          // console.log("pending", this.total)

          // console.log("masuk Admin")
          // console.log("response", response)
        } else {
          this.educationList = []
          this.total = 0
        }
      })
    },
    getSubsidiaryList(){
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll`).then(res => {
        const sub = res.data.data.subsidiaries

        sub.forEach(data => {
          this.subsidiaryList.push({
            text: data.subsidiary_name,
            value: data.id,
          })
        })
      })
    },
    getTotal(){
      this.$axios.get(`${this.$baseUrl}/employeeEducations/total?subsidiary_id=${this.subsidiary_id}`).then(response => {
        if (response.data.data.length !== 0){
          this.totalPending = response.data.data.pending
        }
      })
    },

  },

}
</script>
<style>
.table th, .table td {
    text-align: center;
    color: black;
}
</style>

<template>

  <div
    class="p-1 modalScroll"
    style="width: auto; height: 75vh;"
  >
    <div class="pl-3 pr-3 pt-2">
      <!-- new template basic info-->
      <div
        class="row pt-1"
        style="
              background: none;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
              0px 0px 41px 9px rgba(0, 0, 0, 0.11);
              border-radius: 10px;"
      >
        <div class="col">
          <div
            class="row pl-1"
          >

            <div
              class="saj-title mb-1"
            >

              {{ $t("Education Info") }}
            </div>
          </div>
          <div
            class="row"
          >

            <div class="col-lg-4 saj-text">
              <div class="m-0 mt-1">
                {{ $t ("Employee Name") }} :
              </div>
              <div class="saj-title">
                {{ educationData.employee_name }}
              </div>

            </div>
            <div class="col-lg-4 saj-text">
              <div class="m-0 mt-1">
                {{ $t ("Date") }} :
              </div>
              <div class="saj-title">
                {{ DateChanged(educationData.updated_date) }}
              </div>

            </div>
            <div class="col-lg-4 saj-text">
              <div class="m-0 mt-1">
                {{ $t ("Company") }} :
              </div>
              <div class="saj-title">
                {{ educationData.company_name }}
              </div>

            </div>
          </div>
          <div
            class="row pl-1"
          >
            <div
              class="saj-title mb-1 mt-3"
            >
              {{ $t("Education Details") }}
            </div>
          </div>
          <div
            class="row"
          >

            <div class="col-lg saj-text">

              <div class="saj-text m-0 mt-1">
                {{ $t ("Qualification") }} :
              </div>
              <div class="saj-title">
                {{ $t(educationData.qualification) }}
              </div>
              <div class="saj-text m-0 mt-1">
                {{ $t ("Field of Study") }} :
              </div>
              <div class="saj-title">
                {{ $t(educationData.field_of_study) }}
              </div>
              <div
                v-if="educationData.file === null"
                class="saj-text"
              >
                <feather-icon
                  class="mr-1"
                  icon="PaperclipIcon"
                  size="16"
                />
                {{ $t("No File") }}
              </div>
              <div
                v-if="educationData.file !== null"
                class="saj-text"
              >
                <feather-icon
                  class="mr-1"
                  icon="PaperclipIcon"
                  size="16"
                />
                <a
                  :href="educationData.file.url"
                  target="_blank"
                >
                  {{ educationData.file.name }}
                </a>
              </div>

            </div>
          </div>
          <div
            class="row mt-1"
          >
            <div class="col-lg saj-text">
              <b-form class="mt-2">
                <b-form-group
                  class="saj-text"
                  :label="$t('Justification (For Reject Only)')"
                >
                  <div v-if="educationData.status === 'pending'">
                    <b-form-textarea
                      v-model="description"
                      class="saj-text"
                      :placeholder="$t('Reason for rejection')"
                      rows="3"
                    />
                  </div>

                  <div v-else>
                    <b-form-textarea
                      v-model="educationData.remarks"
                      class="saj-text"
                      rows="3"
                      disabled
                    />
                  </div>

                </b-form-group>
              </b-form>

            </div>
          </div>

        </div>
      </div>
    </div>

    <div
      v-if="educationData.status === 'pending'"
      class="d-flex justify-content-end"
      style=""
    >
      <b-button
        class="btn btn-close m-1"
        aria-label="Close modal"
        style="background: #ffffff !important;
              border-color: red !important;
              color: red !important;
                "
        @click="close()"
      >
        {{ $t('Back') }}
      </b-button>
      <b-button
        class="btn m-1"
        variant="primary"
        style=""
        @click="updateEducation('rejected')"
      >
        {{ $t('Reject') }}
      </b-button>
      <b-button
        class="btn m-1"
        variant="primary"
        style=""
        @click="updateEducation('approved')"
      >
        {{ $t('Approve') }}
      </b-button>
    </div>
    <div
      v-else
      class="d-flex justify-content-end"
      style=""
    >
      <b-button
        class="btn btn-close m-1"
        aria-label="Close modal"
        style="background: #ffffff !important;
              border-color: red !important;
              color: red !important;
                "
        @click="close()"
      >
        {{ $t('Back') }}
      </b-button>

    </div>
  </div>

</template>
<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue"
import { required } from '@validations'
import moment from "moment"
import SAJToast from '@/component/saj-toastification.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormTextarea,
  },
  props: {
    educationData: {
      type: null,
    },
  },
  data() {
    return {
      // item: [],
      description: '',
      displayRemarks: '',
      employee: {
        nama: 'Myra',
        date: '31 April 2022',
        company: 'Ranhill',
        preEducation: 'Sijil Pelajaran Malaysia (SPM)',
        newEducation: 'Degree in Computer Science (UPM)',
      },

      required,
    }
  },
  mounted() {
  },
  methods: {
    DateChanged(date) {
      const newDay = moment(date, "").format("D-MM-yyyy")
      return newDay
    },
    close() {
      this.$emit('close')
    },
    updateEducation(status){
      this.$swal({
        title: `${status === 'approved' ? this.$i18n.t('Are you sure to approve this?') : this.$i18n.t('Are you sure to reject this?')}`,
        text: `${this.$i18n.t("You won't be able to revert this!")}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `${this.$i18n.t('Yes, Submit!')}`,
        cancelButtonText: `${this.$i18n.t('Cancel!')}`,
      }).then(result => {
        if (result.isConfirmed) {
          const data = new FormData()
          data.append('user_id', this.educationData.user_id)
          data.append('education_id', this.educationData.education_id)
          data.append('status', status)
          data.append('remarks', this.description)
          this.$axios.post(`${this.$baseUrl}/admin/updateEmployeeEducation`, data).then(() => {
            if (status === "approved"){
              this.$toast(
                {
                  component: SAJToast,
                  props: {
                    title: `${this.$t('You have approved this request')}.`,
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    titleColor: '#000',
                  },
                },
                {
                  position: "top-right",
                  type: 'info',
                },
              )
              this.$emit('close')
              this.$emit('refresh-table')
            } else {
              this.$toast(
                {
                  component: SAJToast,
                  props: {
                    title: `${this.$t('You have rejected this request')}.`,
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    titleColor: '#000',
                  },
                },
                {
                  position: "top-right",
                  type: 'info',
                },
              )
              this.$emit('close')
              this.$emit('refresh-table')
            }
          })

          this.$swal(
            this.$i18n.t('Successfully Submitted!'),
            ``,
            'success',
          )
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

a {
  color: black;
}
a:hover {
  color: red;
}
</style>
